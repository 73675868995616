<template>
  <div>
    <b-row>
      <b-col>
        <repeat-statistics :data="itemsData" size="3" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <ag-grid-table ref="tablaHistoricoPropuesta" :configUrl="historicoPropuestaConfig"
          :dataUrl="historicoPropuestaData">
        </ag-grid-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,

} from "bootstrap-vue";
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import axios from "@axios";
import RepeatStatistics from "@/views/clients/camanchaca/components/RepeatStatistics";

export default {
  data() {
    return {
      historicoPropuestaConfig: useApiServices.historicoPropuestaConfig,
      historicoPropuestaData: useApiServices.historicoPropuestaData,
      itemsData: [],

    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    AgGridTable,
    RepeatStatistics
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted() {
    this.updateCards()

  },
  methods: {
    refreshInfo() {
      this.updateCards();
      this.$refs.tablaHistoricoPropuesta.refreshRows();
    },

    updateCards() {
      axios.get(useApiServices.historicoPropuestaStatistics)
        .then((response) => {
          this.itemsData = response.data;
        })
        .catch((error) => {

        });
    }
  },
};
</script>
  
<style scoped></style>
  